<template>
  <div id="app">
    <div id="floating-window" class="rounded-5 pe-5">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <div
          class="d-flex flex-column justify-content-center bg-white p-2 rounded-5 text-center"
          style="min-width: 55px; min-height: 55px"
        >
          <i
            class="fa-solid fa-music d-flex justify-content-center"
            :class="{
              'text-danger': isAudioPlaying,
              'text-primary': !isAudioPlaying,
            }"
            style="font-size: 20px"
          ></i>
          <div
            class="text-primary fw-bold text-center"
            :class="{
              'text-danger': isAudioPlaying,
              'text-primary': !isAudioPlaying,
            }"
            style="font-size: 10px"
          >
            BGM
          </div>
        </div>

        <div
          class="bg-white w-100 rounded-5 ms-3 p-3 d-flex justify-content-center align-items-center"
        >
          <i
            class="fa-solid fa-fw me-2 cursor-pointer"
            :class="{
              'fa-pause': isAudioPlaying,
              'fa-play': !isAudioPlaying,
              'text-danger': isAudioPlaying,
              'text-primary': !isAudioPlaying,
            }"
            style="font-size: 24px"
            @click="audioPlay()"
          ></i>
          <i
            class="fa-solid fa-fw me-2"
            :class="{
              'fa-volume-xmark': volume == 0,
              'fa-volume-low': volume > 0,
              'fa-volume-high': volume > 0.6,
            }"
            style="font-size: 14px"
          ></i>
          <input
            type="range"
            id="volume-slider"
            min="0"
            max="1"
            step="0.01"
            style="width: 80px"
            v-model="volume"
          />
          <!-- <span>{{ currentTime + "/" + aduioDuration() }}</span> -->
        </div>
        <audio ref="audio" loop>
          <source src="/files/home/bgm.mp3" type="audio/mp3" />
          您的瀏覽器不支援背景音樂播放。
        </audio>
      </div>
    </div>

    <router-view class="app" />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isAudioPlaying: false,
      currentTime: "0:00",
      volume: 0.5,
    };
  },
  components: {},
  mounted() {
    this.$refs.audio.volume = 0.5;
    // setInterval(() => {
    //   if (typeof this.$refs.audio !== "undefined") {
    //     console.log(this.$refs.audio.currentTime);
    //     const minutes = Math.floor(this.$refs.audio.currentTime / 60);
    //     const seconds = Math.floor(this.$refs.audio.currentTime % 60);
    //     this.currentTime = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    //   } else {
    //     return "0:00";
    //   }
    // }, 1000); // 每秒更新一次
    // window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    audioPlay() {
      if (this.$refs.audio.paused) {
        this.isAudioPlaying = true;
        this.$refs.audio.play();
      } else if (this.$refs.audio.play) {
        this.isAudioPlaying = false;
        this.$refs.audio.pause();
      }
    },
    aduioDuration() {
      if (typeof this.$refs.audio !== "undefined") {
        const minutes = Math.floor(this.$refs.audio.duration / 60);
        const seconds = Math.floor(this.$refs.audio.duration % 60);
        return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
      } else {
        return "0:00";
      }
    },
    updateVolume() {
      this.$refs.audio.volume = this.volume;
    },
    // handleScroll() {
    //   if (window.scrollY > 1) {
    //     const audio = this.$refs.audio;
    //     if (audio.paused) {
    //       audio
    //         .play(() => {
    //           this.isAudioPlaying = ture;
    //         })
    //         .catch((error) => {
    //           console.error("Auto-play failed:", error);
    //         });
    //     }
    //   }
    // },
    // handleUserInteraction() {
    //   const audio = this.$refs.audio;
    //   if (audio.paused) {
    //     audio
    //       .play(() => {
    //         this.isAudioPlaying = ture;
    //       })
    //       .catch((error) => {
    //         console.error("Auto-play failed:", error);
    //       });
    //   }
    // },
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    volume(newVolume) {
      console.log(newVolume);
      this.$refs.audio.volume = newVolume;
    },
  },
};
</script>
<style scoped>
audio {
  display: none;
  visibility: hidden;
  /* position: relative;
  z-index: 999999; */
}
#floating-window {
  position: fixed;
  top: 50%;
  width: 300px;
  right: -224px;
  transform: translateY(-50%);
  background-color: #003e57;
  border: 1px solid #ccc;
  padding: 10px;
  height: 70px;
  overflow: hidden;
  transition: right 0.5s ease-out;
  z-index: 50;
}

#floating-window:hover {
  right: -40px;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>
