import { createWebHistory, createRouter } from "vue-router";
// import store from '@/store/index.js';

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/Index.vue"),
  },
  //計畫簡介(中文)
  {
    path: "/about_project/chinese",
    name: "aboutProjectChinese",
    component: () => import("@/views/about_project/Chinese.vue"),
  },
  //計畫簡介(英文)
  {
    path: "/about_project/english",
    name: "aboutProjectEnglish",
    component: () => import("@/views/about_project/English.vue"),
  },
  //計畫簡介(原住民)
  {
    path: "/about_project/aboriginal",
    name: "aboutProjectAboriginal",
    component: () => import("@/views/about_project/Aboriginal.vue"),
  },
  // 第一集
  {
    path: "/textbook/chapter1",
    name: "chapter1",
    component: () => import("@/views/textbook/Chapter1.vue"),
  },
  // 第二集
  {
    path: "/textbook/chapter2",
    name: "chapter2",
    component: () => import("@/views/textbook/Chapter2.vue"),
  },
  // 第三集
  {
    path: "/textbook/chapter3",
    name: "chapter3",
    component: () => import("@/views/textbook/Chapter3.vue"),
  },
  // 第四集
  {
    path: "/textbook/chapter4",
    name: "chapter4",
    component: () => import("@/views/textbook/Chapter4.vue"),
  },
  // 第五集
  {
    path: "/textbook/chapter5",
    name: "chapter5",
    component: () => import("@/views/textbook/Chapter5.vue"),
  },
  // AR
  {
    path: "/textbook/ar",
    name: "ar",
    component: () => import("@/views/textbook/AR.vue"),
  },
  // VR
  {
    path: "/textbook/vr",
    name: "vr",
    component: () => import("@/views/textbook/VR.vue"),
  },
  // 製作團隊
  {
    path: "/team",
    name: "team",
    component: () => import("@/views/Team.vue"),
  },
  //撲克牌-原住民牌牌讚
  {
    path: "/poker",
    name: "poker",
    component: () => import("@/views/instructional_materials/Poker.vue"),
  },
  //手機人臉刺青合成
  {
    path: "/instructional_materials/face_tattoo",
    name: "instructional_materials_face_tattoo",
    component: () => import("@/views/instructional_materials/FaceTattoo.vue"),
  },
  //工作坊
  {
    path: "/workshop",
    name: "workshop",
    component: () => import("@/views/Workshop.vue"),
  },
  //教學觀摩
  {
    path: "/teaching_observation",
    name: "teaching_observation",
    component: () => import("@/views/TeachingObservation.vue"),
  },
  //找不到網頁404
  {
    path: "/404",
    name: "Error404",
    component: () => import("@/views/Error404.vue"),
  },
  //找不到路徑全部導向404
  {
    path: "/:catchAll(.*)",
    redirect: '/404',
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router 命名
router.beforeEach((to, from, next) => {
  // window.document.title = to.meta.title;
  // remove modal backdrop if one exists
  let modalBackground = document.querySelector('.modal-backdrop')
  if (modalBackground) {
    modalBackground.remove()
  }
  // 獲取body元素
  var bodyElement = document.body;

  // 檢查是否存在class
  if (bodyElement.classList.contains("modal-open")) {
    // 移除class
    bodyElement.classList.remove("modal-open");
  }

  // 檢查是否存在style屬性
  if (bodyElement.hasAttribute("style")) {
    // 移除style屬性
    bodyElement.removeAttribute("style");
  }
  next();
})

// router.beforeResolve( async to=>{
//   if(to.meta.needLogin){
//       const token = store.getters.getLoginToken;
//       if(token === "" || !token) return {name: "Login"}
//   }
// })

export default router;