import { createStore } from 'vuex'

export default createStore({
  state() {
    // 所有在 store 裏的資料
    return {
      isLoading: false,
    }
  },
  actions: {
    // 負責觸發 mutations
    // 可處理非同步程式（e.g: 打 API）
    // setLoading(context, payload) {
    //     context.commit('Set_Loading', payload);
    // },
  },
  mutations: {
    // 負責改變 state 裏的資料
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
  },
  getters: {
    // 像 computed 一樣，運算處理 state 資料
    getIsLoading: (state) => {
      return state.isLoading
    },
    getHeadNav: (state) => {
      return state.headNav
    },
  },
  modules: {
    // 按需求分拆 module
    // 每個 module 都有自己的state, actions, mutations, getters, modules
  }
})